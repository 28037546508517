import React, { useEffect } from 'react'
import FocusAffirmationCategoriesTable from '../components/focusaffirmationcategoriestable/FocusAffirmationCategoriesTable'

const FocusAffirmationCategories = ({ setHeading }) => {

    useEffect(() => {
        setHeading('Affirmation  Category')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <FocusAffirmationCategoriesTable />
                </div>
            </div>
        </>
    )
}

export default FocusAffirmationCategories