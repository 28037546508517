import React, { useEffect, useState } from 'react'
import CategoryForm from '../components/categoryform/CategoryForm'
import { useParams } from 'react-router-dom';
import { getOneCategory } from '../services/CategoryService';
import Loading from '../components/loading/Loading';

const EditCategory = ({ setHeading }) => {
    const { id } = useParams()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getOneCategoryData();
        setHeading('Edit Focus / Affirmation  Category')
    }, []);

    const getOneCategoryData = async () => {
        try {
            setLoading(true)
            const response = await getOneCategory({
                id: id
            });
            setData(response?.data);
            setLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {loading && <Loading />}
            <div>
                <div className={`maindiv`}>
                    <CategoryForm initialData={data} getOneCategoryData={getOneCategoryData} />
                </div>
            </div>
        </>
    )
}

export default EditCategory