import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import style from './assignaudio.module.css'
import { BsUpload } from 'react-icons/bs';
import Loading from '../loading/Loading';
import { AddPersonalAudio } from '../../services/DashboardService';

const AssignAudio = ({ initialData, setOpen }) => {
    const [errors, setErrors] = useState({ audioName: '', description: '', category: '', image: '' });
    const [form, setForm] = useState({ audioName: '', lang: 'english', g_audioName: '', g_description: '', description: '', audioFile: '', image: '' });
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [isEditMode] = useState(false);
    const MAX_FILE_SIZE_MB = 1;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors({});
    }, [])

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setField('audioFile', event.target.value);

        if (selectedFile) {
            const allowedFileType = 'audio/mpeg';

            if (selectedFile.type !== allowedFileType) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    audioFile: "Please upload a valid MP3 file.",
                }));
                return;
            }

            setField('audioFile', selectedFile);
            setSelectedFile(selectedFile);

            setErrors((prevErrors) => ({
                ...prevErrors,
                audioFile: '',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                audioFile: "No file selected.",
            }));
        }
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const fileSizeMB = selectedImage.size / (1024 * 1024);
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

            if (!allowedFileTypes.includes(selectedImage.type)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: "Please upload a valid image file type (JPEG, PNG, GIF, BMP, WEBP).",
                }));
                return;
            }

            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: `File size exceeds ${MAX_FILE_SIZE_MB} MB.`,
                }));
                return;
            }

            setField('image', selectedImage);
            setSelectedImage(selectedImage);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            image: '',
        }));
    };

    const validateForm = () => {
        const { audioName, description, image, lang, g_audioName, g_description, audioFile } = form;

        const newErrors = {};

        if (lang === "english" || lang === "both") {
            if (!audioName || audioName === "") newErrors.audioName = "The audio field is required.";
        }

        if (lang === "german" || lang === "both") {
            if (!g_audioName || g_audioName === "") newErrors.g_audioName = "The german audio field is required.";
        }
        if (lang === "english" || lang === "both") {
            if (!description || description === "") newErrors.description = "The description field is required.";
        }

        if (lang === "german" || lang === "both") {
            if (!g_description || g_description === "") newErrors.g_description = "The german description field is required.";
        }
        if (!image || image === "") newErrors.image = "Please upload a file in suggested format.";
        if (!audioFile || audioFile === "") newErrors.audioFile = "Please upload a file in suggested format.";
        return newErrors;
    };

    const handleOnClose = () => {
        setForm({ audioName: '', lang: 'english', g_audioName: '', g_description: '', description: '', audioFile: '', image: '' })
        setErrors({})
        setOpen(false)
    }

    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        setLoading(true)
        if (Object.keys(newErrors).length === 0) {
            try {
                const formData = new FormData();
                formData.append('userId', initialData?._id);

                if (form.lang === 'english') {
                    formData.append('audioName', form.audioName || '');
                    formData.append('g_audioName', '');
                    formData.append('description', form.description || '');
                    formData.append('g_description', '');
                } else if (form.lang === 'german') {
                    formData.append('audioName', '');
                    formData.append('g_audioName', form.g_audioName || '');
                    formData.append('description', '');
                    formData.append('g_description', form.g_description || '');
                } else if (form.lang === 'both') {
                    formData.append('audioName', form.audioName || '');
                    formData.append('g_audioName', form.g_audioName || '');
                    formData.append('description', form.description || '');
                    formData.append('g_description', form.g_description || '');
                }

                formData.append('image', form.image || '');
                formData.append('audioFile', form.audioFile || '');
                formData.append('lang', form.lang || '');

                const response = await AddPersonalAudio(formData);
                if (response?.error) {
                    setError(response.error);
                } else {
                    setError('');
                    setOpen(false)
                    setForm({ audioName: '', lang: 'english', g_audioName: '', description: '', g_description: '', audioFile: '', image: '' });
                    setSelectedImage('')
                }

            } catch (error) {
                console.error("Error submitting pods:", error);
            }
        } else {
            setErrors(newErrors);
        }
        setLoading(false)
    };

    const languages = [
        { label: 'English', value: 'english' },
        { label: 'German', value: 'german' },
        { label: 'Both', value: 'both' }
    ];

    return (
        <>
            {loading && <Loading />}
            <Form>
                <div className={style.formsection}>
                    <Form.Group controlId='lang' className='d-flex align-items-center gap-3'>
                        <Form.Label className={`${style.modelcontent}`}>Language :</Form.Label>
                        <div className='d-flex gap-3'>
                            {languages.map((language) => (
                                <Form.Check
                                    key={language.value}
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label={`Radio button for ${language.label}`}
                                    label={language.label}
                                    name="lang"
                                    value={language.value}
                                    checked={form.lang === language.value}
                                    onChange={(e) => setField('lang', e.target.value)}
                                    id={language.value}
                                />
                            ))}
                        </div>
                    </Form.Group>
                    <div className='d-lg-flex justify-content-between'>
                        {['english', 'both'].includes(form?.lang) && (
                            <Form.Group controlId='audioName' className='mt-4'>
                                <Form.Label className={`${style.modelcontent}`}>Audio Name :</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`${style.inputfield} form-control mt-sm-3 mt-1`}
                                    value={form?.audioName}
                                    onChange={(e) => setField("audioName", e.target.value)}
                                    placeholder='Enter audio name '
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.audioName && <div className="text-danger mt-2">{errors.audi}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>)}
                        {['german', 'both'].includes(form?.lang) && (
                            <Form.Group controlId='g_audioName' className='mt-4'>
                                <Form.Label className={`${style.modelcontent}`}>Audioname</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`${style.inputfield} form-control mt-sm-3 mt-1`}
                                    value={form?.g_audioName}
                                    onChange={(e) => setField("g_audioName", e.target.value)}
                                    placeholder='Geben Sie den Audionamen ein'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.g_audioName && <div className="text-danger mt-2">{errors.g_audioName}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>)}
                    </div>
                    <div className='d-lg-flex justify-content-between'>
                        {['english', 'both'].includes(form?.lang) && (
                            <Form.Group controlId='description' className='mt-4'>
                                <Form.Label className={`${style.modelcontent}`}>Description :</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                    value={form?.description}
                                    onChange={(e) => setField("description", e.target.value)}
                                    placeholder='Enter description'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description && <div className="text-danger mt-2">{errors.description}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>)}
                        {['german', 'both'].includes(form?.lang) && (
                            <Form.Group controlId='g_description' className='mt-4'>
                                <Form.Label className={`${style.modelcontent}`}>Beschreibung :</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                    value={form?.g_description}
                                    onChange={(e) => setField("g_description", e.target.value)}
                                    placeholder='Beschreibung eingeben'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.g_description && <div className="text-danger mt-2">{errors.g_description}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>)}
                    </div>
                    <Form.Group controlId="image" className='mt-4'>
                        <Form.Label className={`${style.modelcontent}`}>Audio Image :</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            className="visually-hidden"
                            onChange={(e) => handleImageChange(e)}
                            isInvalid={!!errors.file}
                        />
                        <div className={`input-group mt-sm-3 mt-1`}>
                            <label htmlFor="image" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                    <BsUpload />
                                </div>
                            </label>
                        </div>
                        {form?.image && (
                            <div className={`${style.imagedisplay} d-flex gap-3 mt-3 p-2`}>
                                <img
                                    src={selectedImage ? URL.createObjectURL(selectedImage) : `${form?.image}`}
                                    alt="Selected Pod Image"
                                    style={{ width: '85px', height: '50px', borderRadius: '6px' }}
                                />
                                {
                                    <div>
                                        <p className='mb-0'>{selectedImage ? selectedImage.name : form?.image.split('/').pop()}</p>
                                        {selectedImage && <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)} MB</p>}
                                    </div>
                                }
                            </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                            {errors.image && <div className="text-danger mt-2">{errors.image}</div>}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="audioFile" className='mt-4'>
                        <Form.Label className={`${style.modelcontent}`}>Upload Personal Audio :</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            className="visually-hidden"
                            onChange={(e) => handleFileChange(e)}
                            isInvalid={!!errors.file}
                        />
                        <div className={`input-group mt-sm-3 mt-1`}>
                            <label htmlFor="audioFile" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                    <BsUpload />
                                    {selectedFile ? selectedFile?.name : form?.audioFile?.split('/').pop()}
                                </div>
                            </label>
                        </div>
                        <Form.Control.Feedback type="invalid">
                            {errors.audioFile && <div className="text-danger mt-2">{errors.audioFile}</div>}
                        </Form.Control.Feedback>
                    </Form.Group>


                </div>
                {error && <div className="text-danger mt-4">{error}</div>}
                <div className='d-flex justify-content-center gap-3  mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default AssignAudio