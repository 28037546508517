import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import style from './categoryform.module.css';
import success from '../../assets/Success.png';
import { addCategory, updateCategory } from '../../services/CategoryService';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../loading/Loading';

const CategoryForm = ({ initialData, getOneCategoryData }) => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [errors, setErrors] = useState({ name: '', g_name: '' });
    const [error, setError] = useState('');
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [form, setForm] = useState({ created_by: user?.id, type: 1, status: 1, lang: 'english' });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setErrors({ name: '', g_name: '' });
    }, [])


    useEffect(() => {
        if (initialData) {
            setForm({
                created_by: user?.id,
                name: initialData?.name,
                g_name: initialData?.g_name,
                type: initialData?.type,
                status: initialData?.status,
                lang: initialData?.lang,
            });
            setIsEditMode(true);
        }
    }, [initialData]);

    const setField = (field, value) => {
        setForm(prevForm => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const validateForm = () => {
        const { name, g_name, lang } = form;
        const newErrors = {};

        if (lang === "english" || lang === "both") {
            if (!name || name === "") {
                newErrors.name = "The focus / affirmation category field is required.";
            }
        }

        if (lang === "german" || lang === "both") {
            if (!g_name || g_name === "") {
                newErrors.g_name = "The focus / affirmation category field is required.";
            }
        }

        return newErrors;
    };


    const handleOnClose = () => {
        setForm({ type: 1, status: 1, created_by: user?.id, lang: 'english' });
        setErrors({ g_name: '', name: '' });
        navigate('/focuses-affirmation-category')
    };

    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        setLoading(true)
        if (Object.keys(newErrors).length === 0) {
            try {
                if (isEditMode) {
                    if (Object.keys(form).length > 0) {
                        let formDataToSend = { ...form };

                        // Conditionally remove keys based on the selected language
                        if (form.lang === 'english') {
                            formDataToSend.g_name = '';
                        } else if (form.lang === 'german') {
                            formDataToSend.name = '';
                        }

                        const response = await updateCategory(formDataToSend, { id });

                        if (response?.error) {
                            setError(response.error);
                        } else {
                            setError('');
                            getOneCategoryData();
                            setShowSuccessModal(true);
                        }
                    } else {
                        setError('No changes detected');
                    }
                }

                else {
                    try {
                        const response = await addCategory(form);
                        if (response?.error) {
                            setError(response.error);
                        }
                        else {
                            setError('')
                            setShowSuccessModal(true);
                            setForm({ type: 1, status: 1, created_by: user?.id, lang: 'english' }); // Reset form after adding
                        }

                    } catch (error) {
                        console.log(error);
                    }

                }
            } catch (error) {
                console.error("Error submitting category:", error);
            }
        } else {
            setErrors(newErrors);
        }
        setLoading(false)
    };

    const languages = [
        { label: 'English', value: 'english' },
        { label: 'German', value: 'german' },
        { label: 'Both', value: 'both' }
    ];

    const types = [
        { label: 'Affirmation', value: 1 },
        { label: 'Focus', value: 0 }
    ];

    const statuses = [
        { label: 'Active', value: 1 },
        { label: 'In Active', value: 0 }
    ];
    
    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className={style.formsection}>
                    <Form.Group controlId='lang' className='mb-4 d-flex align-items-center gap-3'>
                        <Form.Label className={`${style.modelcontent}`}>Language :</Form.Label>
                        <div className='d-flex gap-3'>
                            {languages.map((language) => (
                                <Form.Check
                                    key={language.value}
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label={`Radio button for ${language.label}`}
                                    label={language.label}
                                    name="lang"
                                    value={language.value}
                                    checked={form.lang === language.value}
                                    onChange={(e) => setField('lang', e.target.value)}
                                    id={language.value}
                                />
                            ))}
                        </div>
                    </Form.Group>
                    <div className='d-lg-flex justify-content-between'>
                        {['english', 'both'].includes(form?.lang) && (
                            <Form.Group controlId='name'>
                                <Form.Label className={`${style.modelcontent}`}>Focus / Affirmation Category :</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                    value={form?.name || ''}
                                    onChange={(e) => setField("name", e.target.value)}
                                    placeholder='Enter Focus / Affirmation name'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name && <div className="text-danger mt-2">{errors.name}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                        {['german', 'both'].includes(form?.lang) && (
                            <Form.Group controlId='g_name' className='m-lg-0 mt-4'>
                                <Form.Label className={`${style.modelcontent}`}>Fokus-/Affirmationskategorie : </Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                    value={form?.g_name || ''}
                                    onChange={(e) => setField("g_name", e.target.value)}
                                    placeholder='Geben Sie den Namen der Fokus-/Bestätigungskategorie ein'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.g_name && <div className="text-danger mt-2">{errors.g_name}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </div>

                    <Form.Group controlId='type' className='mt-4'>
                        <Form.Label className={`${style.modelcontent}`}>Type :</Form.Label>
                        <div className='mt-sm-3 mt-1 d-flex gap-3'>
                            {types.map((type) => (
                                <Form.Check
                                    key={type.value}
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label={`Radio button for ${type.label}`}
                                    label={type.label}
                                    name="type"
                                    value={type.value}
                                    checked={form.type === type.value}
                                    onChange={(e) => setField('type', parseInt(e.target.value))}
                                    id={type.label.toLowerCase()}
                                />
                            ))}
                        </div>
                    </Form.Group>
                    <Form.Group controlId='status' className='mt-4'>
                        <Form.Label className={`${style.modelcontent}`}>Status :</Form.Label>
                        <div className='mt-sm-3 mt-1 d-flex gap-3'>
                            {statuses.map((status) => (
                                <Form.Check
                                    key={status.value}
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label={`Radio button for ${status.label}`}
                                    label={status.label}
                                    name="status"
                                    value={status.value}
                                    checked={form.status === status.value}
                                    onChange={(e) => setField('status', parseInt(e.target.value))}
                                    id={status.label.toLowerCase().replace(' ', '')}
                                />
                            ))}
                        </div>
                    </Form.Group>
                </div>
                {error && <div className="text-danger mt-4">{error}</div>}
                <div className='d-flex  gap-3  mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>{isEditMode ? 'Category updated successfully' : 'Category created successfully'}</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); navigate('/focuses-affirmation-category') }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CategoryForm;