import React, { useEffect } from 'react'
import ImageTextBar from '../components/imagetextbar/ImageTextBar'

const ImageText = ({ setHeading }) => {
  useEffect(() => {
    setHeading('Image & Text')
  }, [])
  
  return (
    <>
      <div>
        <div className={`maindiv`}>
          <ImageTextBar />
        </div>
      </div>
    </>
  )
}

export default ImageText