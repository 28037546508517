import React from 'react';
import style from './paginationdropdown.module.css';
import ReactSelect from 'react-select';

const PaginationDropDown = ({ number, setNumber, setCurrentPage }) => {
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            top: 'auto',
            left: 'auto',
            right: '0',
            bottom: 'auto',
            marginTop: '2px',
            zIndex: 9999,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '80px',
            border: '1px solid #B8B8B8',
            '&:hover': {
                borderRadius: '80px',
                border: '1px solid #B8B8B8',
            },
        }),
        menuList: (provided, state) => ({
            ...provided,
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            backgroundColor: `#FFFFFF`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#769AA3' : '#FFFFFF',
            color: state.isHovered ? '#FFFFFF' : '#769AA3',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#769AA3',
                color: '#FFFFFF',
            },
        }),
    };

    return (
        <div className={style.container}>
            <ReactSelect
                aria-label="Items per page"
                className={`${style.inputfieldselect}`}
                styles={customStyles}
                options={[
                    { value: 10, label: '10' },
                    { value: 20, label: '20' },
                    { value: 30, label: '30' },
                ]}
                value={{ value: number, label: number.toString() }}
                onChange={(option) => { setNumber(option.value); setCurrentPage(0) }}
                isSearchable={false}
            />
        </div>
    );
};

export default PaginationDropDown;