import React, { useEffect } from 'react'
import PodsForm from '../components/podsform/PodsForm'

const AddPods = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Add TransformYourMind Pods')
      }, [])

    return (
        <>
            <div>
            <div className={`maindiv`}>
                    <PodsForm />
                </div>
            </div>
        </>
    )
}

export default AddPods