import React, { useEffect } from 'react'
import AffirmationForm from '../components/affirmationform/AffirmationForm'

const AddAffirmation = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Add Affirmation')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <AffirmationForm />
                </div>
            </div>
        </>
    )
}

export default AddAffirmation