import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import style from './guidesform.module.css';
import success from '../../assets/Success.png';
import { useNavigate } from 'react-router-dom';
import { getGuideline, updateGuideline } from '../../services/GuideLineService';
import Loading from '../loading/Loading';

const GuidesForm = () => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [errors, setErrors] = useState({ description: '' });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [value, setValue] = useState('');
    const [gvalue, setgValue] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const params = {
        created_by: user?.id
    };

    useEffect(() => {
        getGuidelineData();
    }, []);

    const getGuidelineData = async () => {
        try {
            setLoading(true);
            const response = await getGuideline(params);
            setValue(response?.data?.description);
            setgValue(response?.data?.g_description);
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleChange = (content) => {
        setValue(content);
        if (errors.description) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                description: null,
            }));
        }
    };
    const handleChangeG = (content) => {
        setgValue(content);
    };
    const validateForm = () => {
        const newErrors = {};
        if (!value || value === "<p><br></p>") newErrors.description = "The description field is required.";

        return newErrors;
    };

    const handleOnClose = () => {
        setValue('');
        setgValue('');
        setErrors({ description: '' });
        navigate('/troubleshhooting-guides')

    };

    const handleOnSubmit = async () => {
        const newErrors = validateForm();

        if (Object.keys(newErrors).length === 0) {
            const formPayload = {
                description: value,
                g_description: gvalue,
                lang:'both'
            };

            try {
                setLoading(true);
                const response = await updateGuideline(formPayload, { id: data?._id });
                setLoading(false);

                if (response?.error) {
                    setError(response.error);
                } else {
                    setError('');
                    setShowSuccessModal(true);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error updating Guideline:', error);
                setError('An error occurred. Please try again later.');
            }
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className={style.formsection}>
                    <Form.Group controlId='description' >
                        <Form.Label className={style.modelcontent}>Description :</Form.Label>
                        <ReactQuill
                            className={style.richtextbox}
                            value={value}
                            onChange={handleChange}
                            modules={{
                                toolbar: [
                                    [{ size: [] }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    [{ 'align': [] }],
                                    [{ 'color': [] }, { 'background': [] }]
                                ],
                            }}
                            formats={[
                                'size',
                                'bold', 'italic', 'underline',
                                'list', 'bullet', 'align', 'color', 'background',
                            ]}
                            theme="snow"
                        />
                        {errors.description && <div className="text-danger mt-2">{errors.description}</div>}
                    </Form.Group>
                </div>
                <div className={`${style.formsection} mt-4`}>
                    <Form.Group controlId='g_description' >
                        <Form.Label className={style.modelcontent}>Beschreibung :</Form.Label>
                        <ReactQuill
                            className={style.richtextbox}
                            value={gvalue}
                            onChange={handleChangeG}
                            modules={{
                                toolbar: [
                                    [{ size: [] }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    [{ 'align': [] }],
                                    [{ 'color': [] }, { 'background': [] }]
                                ],
                            }}
                            formats={[
                                'size',
                                'bold', 'italic', 'underline',
                                'list', 'bullet', 'align', 'color', 'background',
                            ]}
                            theme="snow"
                        />
                    </Form.Group>
                </div>
                {error && <div className="text-danger mt-4">{error}</div>}
                <div className='d-flex gap-3 mt-4'>
                    <Button className={style.cancelbutton} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={style.submitbutton} onClick={handleOnSubmit}>
                        Update
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <h1 className={`${style.modelcontent} text-center`}>Guideline updated successfully</h1>
                </Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); navigate('/troubleshhooting-guides') }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default GuidesForm;