import { qs } from '../utils/utils';
import api from '../utils/api';

export const getUser = async (data) => {
    try {
        const response = await api.get(`/get-user?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getPersonalAudio = async (data) => {
    try {
        const response = await api.get(`/get-personal-audio?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const AddPersonalAudio = async (data) => {
    try {
        const response = await api.post(`/personal-audio`, data);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const deletePersonalAudio = async (data) => {
    try {
        const response = await api.delete(`/delete-personal-audio`, { data });
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const searchUser = async (data) => {
    try {
        const response = await api.get(`/search-user?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getCount = async () => {
    try {
        const response = await api.get(`/get-count`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}