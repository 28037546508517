import React, { useEffect } from 'react'
import ContactSupportTable from '../components/contactsupporttable/ContactSupportTable'

const Contactsupport = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Contact support')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <ContactSupportTable />
                </div>
            </div>
        </>
    )
}

export default Contactsupport