import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import style from './motivationalmsgform.module.css'
import ReactSelect from 'react-select';
import { BsUpload } from 'react-icons/bs';
import success from '../../assets/Success.png'
import { useNavigate, useParams } from 'react-router-dom';
import { getCategory } from '../../services/CategoryService'
import { addMessage, updateMessage } from '../../services/MotivationalMsgService';
import Loading from '../loading/Loading';

const MotivationalMsgForm = ({ initialData }) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const { id } = useParams();
    const [errors, setErrors] = useState({ category: '', message: '', motivational_image: '' });
    const [form, setForm] = useState({ category: '', message: '', g_message: '', motivational_image: '', status: 1, lang: 'english' });
    const [selectedImage, setSelectedImage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [error, setError] = useState('');
    const MAX_FILE_SIZE_MB = 1;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setErrors({ category: '', message: '', motivational_image: '' });
    }, [])

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    useEffect(() => {
        if (initialData) {
            setForm({
                message: initialData?.message,
                g_message: initialData?.g_message,
                motivational_image: initialData?.motivational_image,
                status: initialData?.status,
                category: initialData?.category,
                lang: initialData?.lang
            });
            setIsEditMode(true);
        }
    }, [initialData]);

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            top: 'auto',
            left: 'auto',
            right: '0',
            bottom: 'auto',
            marginTop: '10px',
            zIndex: 9999,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '80px',
            border: '1px solid #B8B8B8',
            '&:hover': {
                borderRadius: '80px',
                border: '1px solid #B8B8B8',
            },
        }),
        menuList: (provided, state) => ({
            ...provided,
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            backgroundColor: `#FFFFFF`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#769AA3' : '#FFFFFF',
            color: state.isHovered ? '#FFFFFF' : '#769AA3',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#769AA3',
                color: '#FFFFFF',
            },
        }),
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const fileSizeMB = selectedImage.size / (1024 * 1024);
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

            if (!allowedFileTypes.includes(selectedImage.type)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    motivational_image: "Please upload a valid image file type (JPEG, PNG, GIF, BMP, WEBP).",
                }));
                return;
            }

            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    motivational_image: `File size exceeds ${MAX_FILE_SIZE_MB} MB.`,
                }));
                return;
            }

            setField('motivational_image', selectedImage);
            setSelectedImage(selectedImage);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            file: '',
        }));
    };

    const validateForm = () => {
        const { lang, message, g_message, category, motivational_image } = form;
        const newErrors = {};

        if (lang === 'english' || lang === 'both') {
            if (!message || message === "") {
                newErrors.message = "The motivational message field is required.";
            }
        }

        if (lang === 'german' || lang === 'both') {
            if (!g_message || g_message === "") {
                newErrors.g_message = "Das motivierende Nachrichtfeld ist erforderlich.";  // German error message
            }
        }

        if (!category || category === "") {
            newErrors.category = "The category field is required.";
        }

        if (!motivational_image || motivational_image === "") {
            newErrors.motivational_image = "Upload motivational image is required.";
        }

        return newErrors;
    };

    const handleOnClose = () => {
        setForm({ category: '', g_message: '', message: '', motivational_image: '', status: 1, lang: 'english' })
        setErrors({ category: '', message: '', motivational_image: '' })
        navigate('/motivational-messages')
    }

    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        setLoading(true)
        if (Object.keys(newErrors).length === 0) {
            try {
                const formData = new FormData();

                if (form.lang === 'english') {
                    formData.append('message', form.message || '');
                    formData.append('g_message', '');
                } else if (form.lang === 'german') {
                    formData.append('message', '');
                    formData.append('g_message', form.g_message || '');
                } else if (form.lang === 'both') {
                    formData.append('message', form.message || '');
                    formData.append('g_message', form.g_message || '');
                }
                formData.append('created_by', user?.id);
                formData.append('motivational_image', form.motivational_image);
                formData.append('category', form.category);
                formData.append('status', form.status);
                formData.append('lang', form.lang);

                if (isEditMode) {
                    const response = await updateMessage(formData, { id: id });
                    if (response?.error) {
                        setError(response.error);
                    } else {
                        setError('');
                        setShowSuccessModal(true);
                    }
                } else {
                    const response = await addMessage(formData);
                    if (response?.error) {
                        setError(response.error);
                    } else {
                        setError('');
                        setShowSuccessModal(true);
                        setForm({ name: '', g_message: '', description: '', category: '', audioFile: '', status: 1, filename: '' });
                        setSelectedImage('')
                    }
                }
            } catch (error) {
                console.error("Error submitting motivational message:", error);
            }
        } else {
            setErrors(newErrors);
        }
        setLoading(false)
    };

    useEffect(() => {
        getCategoryData();
    }, []);

    const params = {
        created_by: user?.id,
        type: 0,
        isAdmin: true
    };

    const getCategoryData = async () => {
        try {
            const response = await getCategory(params);
            setCategoryData(response?.data);
        } catch (error) {
            console.error(error);
        }
    };

    const languages = [
        { label: 'English', value: 'english' },
        { label: 'German', value: 'german' },
        { label: 'Both', value: 'both' }
    ];
    
    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className='d-lg-flex gap-3'>
                    <div className={style.formsection}>
                        <Form.Group controlId='lang' className='mb-4 d-flex align-items-center gap-3'>
                            <Form.Label className={`${style.modelcontent}`}>Language :</Form.Label>
                            <div className='d-flex gap-3'>
                                {languages.map((language) => (
                                    <Form.Check
                                        key={language.value}
                                        type="radio"
                                        className={`${style.radiobutton}`}
                                        aria-label={`Radio button for ${language.label}`}
                                        label={language.label}
                                        name="lang"
                                        value={language.value}
                                        checked={form.lang === language.value}
                                        onChange={(e) => setField('lang', e.target.value)}
                                        id={language.value}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                        <div className='d-lg-flex justify-content-between'>
                            {['english', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='message'>
                                    <Form.Label className={`${style.modelcontent}`}>Motivational Message :</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                        value={form?.message}
                                        onChange={(e) => setField("message", e.target.value)}
                                        placeholder='Enter motivational message'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.message && <div className="text-danger mt-2">{errors.message}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>)}
                            {['german', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='g_message'>
                                    <Form.Label className={`${style.modelcontent}`}>Motivierende Botschaft:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                        value={form?.g_message}
                                        onChange={(e) => setField("g_message", e.target.value)}
                                        placeholder='Geben Sie eine motivierende Nachricht ein'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.g_message && <div className="text-danger mt-2">{errors.g_message}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>)}
                        </div>
                        <Form.Group controlId='category' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Select Category :</Form.Label>
                            <ReactSelect
                                aria-label="Default select example"
                                className={`${style.inputfieldselect} mt-sm-3 mt-1`}
                                styles={customStyles}
                                options={[
                                    ...categoryData?.map((record) => ({
                                        value: record?.name,
                                        label: `${record?.name}  ${record?.g_name ? '/' + record?.g_name : ''}`,
                                    }))
                                ]}
                                value={{ value: form?.category, label: form?.category ? form?.category : 'Select Category' }} // Set the value of the select box
                                onChange={(selectedOption) => setField('category', selectedOption?.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.category && <div className="text-danger mt-2">{errors.category}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="motivational_image" className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Upload Motivational Image :</Form.Label>
                            <Form.Control
                                type="file"
                                size="sm"
                                className="visually-hidden"
                                onChange={(e) => handleImageChange(e)}
                                isInvalid={!!errors.file}
                            />
                            <p className={style.error}>{errors?.file}</p>
                            <div className={`input-group mt-sm-3 mt-1`}>
                                <label htmlFor="motivational_image" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                    <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                        <BsUpload />
                                    </div>
                                </label>
                            </div>
                            {form?.motivational_image && (
                                <div className={`${style.imagedisplay} d-flex gap-3 mt-3 p-2`}>
                                    <img
                                        src={selectedImage ? URL.createObjectURL(selectedImage) : `${form?.motivational_image}`}
                                        alt="Selected Pod Image"
                                        style={{ width: '85px', height: '50px', borderRadius: '6px' }}
                                    />
                                    {
                                        <div>
                                            <p className='mb-0'>{selectedImage ? selectedImage.name : form?.motivational_image?.split('/').pop()}</p>
                                            {selectedImage && <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)} MB</p>}
                                        </div>
                                    }
                                </div>
                            )}
                            <Form.Control.Feedback type="invalid">
                                {errors.motivational_image && <div className="text-danger mt-2">{errors.motivational_image}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='status' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Status :</Form.Label>
                            <div className='mt-sm-3 mt-1 d-flex gap-3'>
                                <Form.Check
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label="Checkbox for following text input"
                                    label="Active"
                                    name="status"
                                    value={1}
                                    checked={form.status === 1}
                                    onChange={(e) => setField('status', 1)}
                                    id='active'
                                />
                                <Form.Check
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label="Checkbox for following text input"
                                    label="In Active"
                                    name="status"
                                    value={0}
                                    checked={form.status === 0}
                                    onChange={(e) => setField('status', 0)}
                                    id='inactive'
                                />
                            </div>
                        </Form.Group>
                    </div>

                </div>
                {error && <div className="text-danger mt-2 mt-4">{error}</div>}
                <div className='d-flex  gap-3  mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>{isEditMode ? 'Motivational Message updated successfully' : 'Motivational Message created successfully'}</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); navigate('/motivational-messages') }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default MotivationalMsgForm