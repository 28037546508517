import React, { useEffect } from 'react'
import MotivationalMsgForm from '../components/motivationalmsgform/MotivationalMsgForm'

const AddMotivationalMsg = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Add Motivational Messages')
    }, [])
    
    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <MotivationalMsgForm />
                </div>
            </div>
        </>
    )
}

export default AddMotivationalMsg