import React, { useEffect } from 'react'
import DashboardTable from '../components/dashboardtable/DashboardTable'
import CountBar from '../components/dashboardcountbar/CountBar'

const Dashboard = ({ setHeading }) => {

  useEffect(() => {
    setHeading('Dashboard')
  }, [])

  return (
    <>
      <div>
        <div className={`maindiv`}>
          <CountBar />
          <DashboardTable />
        </div>
      </div>
    </>
  )
}

export default Dashboard