import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import style from './imagetextform.module.css';
import { BsUpload } from 'react-icons/bs';
import success from '../../assets/Success.png';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading/Loading';
import { updateScreen } from '../../services/ImageText';

const ImageTextForm = ({ initialData, setShowView, getScreenData }) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [errors, setErrors] = useState({ authorName: '', quote: '', image: '' });
    const [form, setForm] = useState({ authorName: '', g_authorName: '', quote: '', g_quote: '', image: '', lang: 'english' });
    const [selectedImage, setSelectedImage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setErrors({ authorName: '', quote: '', image: '' });
    }, []);

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    useEffect(() => {
        if (initialData) {
            setForm({
                authorName: initialData?.authorName || '',
                g_authorName: initialData?.g_authorName || '',
                quote: initialData?.quote || '',
                g_quote: initialData?.g_quote || '',
                image: initialData?.image || ''
            });
        }
    }, [initialData]);

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const fileSizeMB = selectedImage.size / (1024 * 1024);
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

            if (!allowedFileTypes.includes(selectedImage.type)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: "Please upload a valid image file type (JPEG, PNG, GIF, BMP, WEBP).",
                }));
                return;
            }

            if (fileSizeMB > 1) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: `File size exceeds 1 MB.`,
                }));
                return;
            }

            setField('image', selectedImage);
            setSelectedImage(selectedImage);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            file: '',
        }));
    };

    const validateForm = () => {
        const { authorName, quote, image } = form;

        const newErrors = {};

        if (!authorName || authorName === "") newErrors.authorName = "The author name field is required.";
        if (!quote || quote === "") newErrors.quote = "The quote field is required.";
        if (!image || image === "") newErrors.image = "The upload image field is required.";
        return newErrors;
    };

    const handleOnClose = () => {
        setForm({ authorName: '', g_authorName: '', quote: '', g_quote: '', image: '' });
        setErrors({ authorName: '', quote: '', image: '' });
        setShowView(false)
        navigate('/image-text');
    };

    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        if (Object.keys(newErrors).length === 0) {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('created_by', user?.id);
                formData.append('authorName', form.authorName);
                formData.append('g_authorName', form.g_authorName);
                formData.append('quote', form.quote);
                formData.append('g_quote', form.g_quote);
                formData.append('image', form.image);
                formData.append('lang', 'both');

                await updateScreen(formData, { id: initialData?._id });
                setShowView(false)
                getScreenData()
            } catch (error) {
                console.error("Error submitting Image & Text message:", error);
            }
            setLoading(false);
        } else {
            setErrors(newErrors);
        }
    };


    return (
        <>
            {loading && <Loading />}
            <Form>
                <div className={style.formsection}>
                    <div className='d-lg-flex justify-content-between'>
                        <Form.Group controlId='authorName'>
                            <Form.Label className={`${style.modelcontent}`}>Author name :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                value={form?.authorName}
                                onChange={(e) => setField("authorName", e.target.value)}
                                placeholder='Enter author name'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.authorName && <div className="text-danger mt-2">{errors.authorName}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='g_authorName' className='mt-lg-0 mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Autorenname :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                value={form?.g_authorName}
                                onChange={(e) => setField("g_authorName", e.target.value)}
                                placeholder='Geben Sie den Namen des Autors ein'
                            />
                        </Form.Group>
                    </div>
                    <div className='d-lg-flex justify-content-between'>
                        <Form.Group controlId='quote' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Quote :</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                value={form?.quote}
                                onChange={(e) => setField("quote", e.target.value)}
                                placeholder='Enter quote'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.quote && <div className="text-danger mt-2">{errors.quote}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='g_quote' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Zitat :</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                value={form?.g_quote}
                                onChange={(e) => setField("g_quote", e.target.value)}
                                placeholder='Zitat eingeben'
                            />
                        </Form.Group>
                    </div>
                    <Form.Group controlId="image" className='mt-4'>
                        <Form.Label className={`${style.modelcontent}`}>Image :</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            className="visually-hidden"
                            onChange={(e) => handleImageChange(e)}
                            isInvalid={!!errors.file}
                        />
                        <p className={style.error}>{errors?.file}</p>
                        <div className={`input-group mt-sm-3 mt-1`}>
                            <label htmlFor="image" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                    <BsUpload />
                                </div>
                            </label>
                        </div>
                        {form?.image && (
                            <div className={`${style.imagedisplay} d-flex gap-3 mt-3 p-2`}>
                                <img
                                    src={selectedImage ? URL.createObjectURL(selectedImage) : `${form?.image}`}
                                    alt="Selected Pod Image"
                                    style={{ width: '85px', height: '50px', borderRadius: '6px' }}
                                />
                                {
                                    <div>
                                        <p className='mb-0'>{selectedImage ? selectedImage.name : form?.image?.split('/').pop()}</p>
                                        {selectedImage && <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)} MB</p>}
                                    </div>
                                }
                            </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                            {errors.image && <div className="text-danger mt-2">{errors.image}</div>}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div className='d-flex  gap-3  mt-4'>
                        <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                            Cancel
                        </Button>
                        <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                            Update
                        </Button>
                    </div>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>Image & Text updated successfully</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ImageTextForm