import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import style from './affirmationform.module.css'
import ReactSelect from 'react-select';
import { BsUpload } from 'react-icons/bs';
import success from '../../assets/Success.png'
import { useNavigate, useParams } from 'react-router-dom';
import { addAffirmation, updateAffirmation } from '../../services/AffirmationService';
import { getCategory } from '../../services/CategoryService'
import Loading from '../loading/Loading';

const AffirmationForm = ({ initialData }) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const { id } = useParams();
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({ description: '', g_description: '', category: '' });
    const [form, setForm] = useState({ description: '', category: '', g_description: '', audioFile: '', status: 1, lang: 'english' });
    const [selectedFile, setSelectedFile] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setErrors({ description: '', g_description: '', category: '' });
    }, [])

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    useEffect(() => {
        if (initialData) {
            setForm({
                g_description: initialData?.g_description,
                description: initialData?.description,
                status: initialData?.status,
                category: initialData?.category,
                audioFile: initialData?.audioFile,
                lang: initialData?.lang
            });
            setIsEditMode(true);
        }
    }, [initialData]);

    const handleOnClose = () => {
        setForm({ category: '', audioFile: '', status: 1 })
        setErrors({ description: '', g_description: '', category: '' })
        setSelectedFile('')
        navigate('/affirmation')
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            top: 'auto',
            left: 'auto',
            right: '0',
            bottom: 'auto',
            marginTop: '10px',
            zIndex: 9999,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '80px',
            border: '1px solid #B8B8B8',
            '&:hover': {
                borderRadius: '80px',
                border: '1px solid #B8B8B8',
            },
        }),
        menuList: (provided, state) => ({
            ...provided,
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            backgroundColor: `#FFFFFF`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#769AA3' : '#FFFFFF',
            color: state.isHovered ? '#FFFFFF' : '#769AA3',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#769AA3',
                color: '#FFFFFF',
            },
        }),
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setField('audioFile', event.target.value);

        if (selectedFile) {
            const allowedFileType = 'audio/mpeg';

            if (selectedFile.type !== allowedFileType) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    audioFile: "Please upload a valid MP3 file.",
                }));
                return;
            }
            setField('audioFile', selectedFile);
            setSelectedFile(selectedFile);

            setErrors((prevErrors) => ({
                ...prevErrors,
                file: '',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                file: "No file selected.",
            }));
        }
    };

    const validateForm = () => {
        const { description, category, g_description, lang } = form;

        const newErrors = {};

        if (lang === "english" || lang === "both") {
            if (!description || description === "") newErrors.description = "The description field is required.";
        }

        if (lang === "german" || lang === "both") {
            if (!g_description || g_description === "") newErrors.g_description = "The german description field is required.";
        }

        if (!category || category === "") newErrors.category = "The select categories field is required.";

        return newErrors;
    };


    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        setLoading(true)
        if (Object.keys(newErrors).length === 0) {
            try {
                const formData = new FormData();
                formData.append('created_by', user?.id);

                if (form.lang === 'english') {
                    formData.append('description', form.description || '');
                    formData.append('g_description', '');
                } else if (form.lang === 'german') {
                    formData.append('description', '');
                    formData.append('g_description', form.g_description || '');
                } else if (form.lang === 'both') {
                    formData.append('description', form.description || '');
                    formData.append('g_description', form.g_description || '');
                }

                formData.append('category', form.category || '');
                formData.append('audioFile', selectedFile || '');
                formData.append('status', form.status !== null && form.status !== undefined ? form.status : '');
                formData.append('isDefault', true);
                formData.append('lang', form.lang);

                if (isEditMode) {
                    const response = await updateAffirmation(formData, { id: id });
                    if (response?.error) {
                        setError(response.error);
                    } else {
                        setError('');
                        setShowSuccessModal(true);
                    }
                }
                else {
                    const response = await addAffirmation(formData);
                    if (response?.error) {
                        setError(response.error);
                    } else {
                        setError('');
                        setShowSuccessModal(true);
                        setForm({ category: '', audioFile: '', status: 1 });
                        setSelectedFile('')
                    }
                }
            } catch (error) {
                console.error("Error submitting affirmation:", error);
            }
        } else {
            setErrors(newErrors);
        }
        setLoading(false)
    };

    useEffect(() => {
        getCategoryData();
    }, []);

    const params = {
        created_by: user?.id,
        type: 1,
        isAdmin: true
    };

    const getCategoryData = async () => {
        try {
            const response = await getCategory(params);
            setCategoryData(response?.data);
        } catch (error) {
            console.error(error);
        }
    };

    const languages = [
        { label: 'English', value: 'english' },
        { label: 'German', value: 'german' },
        { label: 'Both', value: 'both' }
    ];

    const statuses = [
        { label: 'Active', value: 1 },
        { label: 'In Active', value: 0 }
    ];

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className='d-lg-flex gap-3'>
                    <div className={style.formsection}>
                        <Form.Group controlId='lang' className='mb-4 d-flex align-items-center gap-3'>
                            <Form.Label className={`${style.modelcontent}`}>Language :</Form.Label>
                            <div className='d-flex gap-3'>
                                {languages?.map((language) => (
                                    <Form.Check
                                        key={language.value}
                                        type="radio"
                                        className={`${style.radiobutton}`}
                                        aria-label={`Radio button for ${language.label}`}
                                        label={language.label}
                                        name="lang"
                                        value={language.value}
                                        checked={form.lang === language.value}
                                        onChange={(e) => setField('lang', e.target.value)}
                                        id={language.value}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                        <Form.Group controlId='Category'>
                            <Form.Label className={`${style.modelcontent}`}>Select Category :</Form.Label>
                            <ReactSelect
                                aria-label="Default select example"
                                className={`${style.inputfieldselect} mt-sm-3 mt-1`}
                                styles={customStyles}
                                options={[
                                    ...categoryData?.map((record) => ({
                                        value: record?.name,
                                        label: `${record?.name ? record?.name : '-'}  ${record?.g_name ? ' / ' + record?.g_name : ''}`,
                                    }))
                                ]}
                                value={{ value: form?.category, label: form?.category ? form?.category : 'Select Category' }}
                                onChange={(selectedOption) => setField('category', selectedOption?.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.category && <div className="text-danger mt-2">{errors?.category}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className='d-lg-flex justify-content-between'>
                            {['english', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='description' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Description :</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                        value={form?.description || ''}
                                        onChange={(e) => setField("description", e.target.value)}
                                        placeholder='Enter description'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.description && <div className="text-danger mt-2">{errors?.description}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>)}
                            {['german', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='g_description' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Beschreibung :</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                        value={form?.g_description || ''}
                                        onChange={(e) => setField("g_description", e.target.value)}
                                        placeholder='Beschreibung eingeben'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.g_description && <div className="text-danger mt-2">{errors?.g_description}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>)}
                        </div>
                        <Form.Group controlId="audioFile" className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Upload Alarms Audio :</Form.Label>
                            <Form.Control
                                type="file"
                                size="sm"
                                className="visually-hidden"
                                onChange={(e) => handleFileChange(e)}
                                isInvalid={!!errors.file}
                            />
                            <div className={`input-group mt-sm-3 mt-1`}>
                                <label htmlFor="audioFile" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                    <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                        <BsUpload />
                                        {selectedFile ? selectedFile?.name : form?.audioFile?.split('/').pop()}
                                    </div>
                                </label>
                            </div>
                        </Form.Group>
                        <Form.Group controlId='status' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Status :</Form.Label>
                            <div className='mt-sm-3 mt-1 d-flex gap-3'>
                                {statuses.map((status) => (
                                    <Form.Check
                                        key={status.value}
                                        type="radio"
                                        className={`${style.radiobutton}`}
                                        aria-label={`Radio button for ${status.label}`}
                                        label={status.label}
                                        name="status"
                                        value={status.value}
                                        checked={form.status === status.value}
                                        onChange={(e) => setField('status', parseInt(e.target.value))}
                                        id={status.label.toLowerCase().replace(' ', '')}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                {error && <div className="text-danger mt-2 mt-4">{error}</div>}
                <div className='d-flex gap-3  mt-4'>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>{isEditMode ? 'Affirmation updated successfully' : 'Affirmation created successfully'}</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); navigate('/affirmation') }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default AffirmationForm