import React, { useEffect } from 'react'
import GuidesForm from '../components/guidesform/GuidesForm'

const EditGuides = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Edit Troubleshooting Guides')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <GuidesForm />
                </div>
            </div>
        </>
    )
}

export default EditGuides