import React, { useEffect } from 'react'
import TroubleshootingGuidesDetails from '../components/troubleshootingguidesdetails/TroubleshootingGuidesDetails'

const TroubleshootingGuides = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Troubleshooting guides')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <TroubleshootingGuidesDetails />
                </div>
            </div>
        </>
    )
}

export default TroubleshootingGuides