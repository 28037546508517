import React, { useEffect } from 'react'
import TransformMindPodsTable from '../components/transformmindpodstable/TransformMindPodsTable'

const TransformMindPods = ({ setHeading }) => {
    useEffect(() => {
        setHeading('TransformYourMind Pods')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <TransformMindPodsTable />
                </div>
            </div>
        </>
    )
}

export default TransformMindPods