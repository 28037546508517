import api from "../utils/api";

export const login = async (data) => {
    try {
        const response = await api.post('/login', data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}