import { Fragment, useContext, useState } from 'react';
import {
	Accordion,
	Button,
	Dropdown,
	Form,
	Modal,
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import style from './navbarvertical.module.css'
import { LuLayoutDashboard } from 'react-icons/lu';
import { PiBookOpenText } from 'react-icons/pi';
import { BiSupport } from 'react-icons/bi';
import { MdOutlinePrivacyTip } from 'react-icons/md';
import logout from '../../assets/logout.png'
import { CiImageOn } from "react-icons/ci";

const NavbarVertical = ({ onLinkClick }) => {
	const location = useLocation();
	const [openAccordionItem, setOpenAccordionItem] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate()
	const handleAccordionToggle = (eventKey) => {
		setOpenAccordionItem(openAccordionItem === eventKey ? null : eventKey);
	};

	const DashboardMenu = [
		{
			title:'Dashboard',
			link: '/',
			icon: <LuLayoutDashboard />
		},
		{
			title: 'Management',
			icon: <PiBookOpenText />,
			subMenu: [
				{
					title: 'Affirmation  Category',
					link: '/focuses-affirmation-category',
				},
				{
					title: 'Affirmation',
					link: '/affirmation'
				},
				{
					title: 'TransformYourMind Pods',
					link: '/transformyourmind-pods',
				},
				{
					title: 'Motivational Messages',
					link: '/motivational-messages'
				}

			]
		},
		{
			title: 'Support',
			icon: <BiSupport />,
			subMenu: [
				{
					title: 'FAQ',
					link: '/faq',
				},
				{
					title: 'Contact support',
					link: '/contact-support'
				},
				{
					title: 'Troubleshooting guides',
					link: '/troubleshhooting-guides',
				}
			]
		},
		{
			title: 'Privacy & Policy',
			link: '/privacy-policy',
			icon: <MdOutlinePrivacyTip />
		},
		{
			title: 'Image & Text',
			link: '/image-text',
			icon: <CiImageOn />
		},
	];
	const handleOnLogOut = () => {
		navigate('/login')
		localStorage.removeItem('userdata')
		localStorage.removeItem('token')
	}

	return (
		<Fragment>
			<SimpleBar style={{ maxHeight: '100vh', height: '100%' }}>
				<div className="nav-scroller">
					<Link
						onClick={() => { onLinkClick() }}
						to="/" className={`navbar-brand text-center p-3 ${style.navbarbrand}`}>
						<p className={`${style.heading}`}>TransformYourMind</p>
					</Link>
				</div>

				{/* Dashboard Menu */}

				<div className='mt-lg-3'>
					{DashboardMenu.map((item, index) => {
						const isActive = location.pathname === item.link || (item.link !== '/' && location.pathname.startsWith(item.link));

						return (
							<li key={index} className="nav-item my-2">
								{item.subMenu ? (
									<Accordion className='border-0' activeKey={openAccordionItem}>
										<Accordion.Item
											key={index}
											eventKey={index.toString()}
											className={`nav-link p-0 border-0 ${openAccordionItem === index.toString() ? style.accordionitemactive : ''}`}
										>
											<Accordion.Header
												className={`mx-md-2 mx-1 py-0 ${style.navbarheading} ${item?.subMenu?.some(subItem => location.pathname.startsWith(subItem.link)) ? style.active : ''} ${openAccordionItem === index?.toString() ? style.open : ''}`}
												onClick={() => handleAccordionToggle(index.toString())}
											>
												{item?.icon}&nbsp;{item.title}
											</Accordion.Header>
											<Accordion.Body className={`${openAccordionItem === index.toString() ? style.accordionbody : ''} ${item?.subMenu?.some(subItem => location.pathname.startsWith(subItem.link)) ? style.accordionbody : 'mx-md-2 mx-1'} p-0 border-0`}>
												{item.subMenu.map((subItem, subIndex) => {
													const isSubActive = location.pathname === subItem.link || location.pathname.startsWith(subItem.link);

													return (
														<Dropdown.Item
															key={subIndex}
															as={Link}
															to={subItem.link}
															className={`${style.submenu} ${isSubActive ? style.activesubmenu : ''}`}
															onClick={() => { onLinkClick() }}
														>
															{subItem.title}
														</Dropdown.Item>
													);
												})}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								) : (
									<Link
										to={item.link}
										className={`nav-link mx-md-2 mx-1 ${isActive ? style.activemenu : ''} ${style.navbarheading}`}
										onClick={() => { onLinkClick() }}
									>
										{item?.icon} &nbsp;{item.title}
									</Link>
								)}
							</li>
						);
					})}
				</div>

				{/* end of Dashboard Menu */}

				<li className="nav-item">
					<div className={` ${style.username} ps-4`}>
						{/* <div className='d-flex align-items-center gap-3 mb-2'>
							<h4 className={`${style.lightmode} mb-0`}>German</h4>
							<Form.Check
								className={style.switchmode}
								type="switch"
								id="custom-switch"
								checked={i18n.language === 'de'}
								onChange={(e) => i18n.changeLanguage(e.target.checked ? 'de' : 'en')}
							/>
						</div> */}
						<button className='btn p-0 d-flex align-items-center' onClick={() => setShowModal(true)}>
							<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.25 5H7.5C6.11925 5 5 6.11929 5 7.5V22.5C5 23.8807 6.11925 25 7.5 25H11.25M20 10L25 15M25 15L20 20M25 15H10" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<h4 className={`${style.navbarheading} mb-0`}>Logout</h4>
						</button>
					</div>
				</li>
			</SimpleBar>
			<Modal size='sm' show={showModal} onHide={() => setShowModal(false)} contentClassName='px-2 pt-5' centered backdrop='static' keyboard={false}>
				<Modal.Header className='border-0 p-0 justify-content-center'>
					<img src={logout} alt="Success" style={{ height: '150px' }} />
				</Modal.Header>
				<Modal.Body className='px-0 pt-4 pb-0'><h1 className={`${style.modelcontent} text-center`}>Are you sure you want to logout ?</h1></Modal.Body>
				<Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
					<Button className={style.submitbutton} onClick={handleOnLogOut}>
						Yes
					</Button>
					<Button className={style.submitbutton} onClick={() => { setShowModal(false) }}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default NavbarVertical;