import React, { useEffect, useState } from 'react'
import PodsForm from '../components/podsform/PodsForm'
import { getOnePod } from '../services/TransFormPodsService';
import { useParams } from 'react-router-dom';
import Loading from '../components/loading/Loading';

const EditPods = ({ setHeading }) => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getOnePodData();
        setHeading('Edit TransformYourMind Pods')
    }, []);

    const getOnePodData = async () => {
        try {
            setLoading(true);
            const response = await getOnePod({ id });
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {loading && <Loading />}
            <div>
                <div className={`maindiv`}>
                    <PodsForm initialData={data} />
                </div>
            </div>
        </>
    )
}

export default EditPods