import React, { useEffect } from 'react'
import CategoryForm from '../components/categoryform/CategoryForm'

const AddCategory = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Add Focus / Affirmation  Category')
      }, [])
      
    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <CategoryForm />
                </div>
            </div>
        </>
    )
}

export default AddCategory