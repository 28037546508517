import { qs } from '../utils/utils';
import api from '../utils/api';

export const getPod = async (data) => {
    try {
        const response = await api.get(`/get-pod?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getOnePod = async (data) => {
    try {
        const response = await api.get(`/one-pod?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updatePod = async (data,params) => {
    try {
        const response = await api.post(`/update-pod?${qs(params)}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const deleteOnePod = async (data) => {
    try {
        const response = await api.delete(`/delete-pod?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const addPod = async (data) => {
    try {
        const response = await api.post(`/add-pod`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const searchPod = async (data) => {
    try {
        const response = await api.get(`/search-pod?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}