import React, { useEffect, useState } from 'react';
import AffirmationForm from '../components/affirmationform/AffirmationForm';
import { useParams } from 'react-router-dom';
import { getOneAffirmation } from '../services/AffirmationService';
import Loading from '../components/loading/Loading';

const EditAffirmation = ({ setHeading }) => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getOneAffirmationData();
        setHeading('Edit Affirmation')
    }, []);

    const getOneAffirmationData = async () => {
        try {
            setLoading(true);
            const response = await getOneAffirmation({ id });
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {loading && <Loading />}
            <div>
                <div className={`maindiv`}>
                    <AffirmationForm initialData={data} />
                </div>
            </div>
        </>
    );
};

export default EditAffirmation;