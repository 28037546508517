import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './privacypolicydetails.module.css'
import { FiEdit } from 'react-icons/fi'
import { getPolicy } from '../../services/PrivacyPolicyService'
import Loading from '../loading/Loading'
import { Tab, Tabs } from 'react-bootstrap'

const PrivacyPolicyDetails = () => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState('english');

    const params = {
        created_by: user?.id,
        isAdmin:true
    };

    useEffect(() => {
        getPolicyData();
    }, []);

    const getPolicyData = async () => {
        try {
            setLoading(true);
            const response = await getPolicy(params);
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            {loading && <Loading />}
            <div className='mt-sm-4 mt-2 d-lg-flex mb-sm-3 mb-2 justify-content-end'>
                <button className={`btn ${style.editbutton} me-2`} onClick={() => navigate(`/privacy-policy/edit`)} >
                    <FiEdit className={style.edit} />
                </button>
            </div>
            <div className='bg-white p-4'>
                <Tabs
                    id="fill-tab-example"
                    activeKey={activeKey}
                    onSelect={(k) => setActiveKey(k)}
                    className="mb-3 border-0"
                >
                    <Tab eventKey="english" title={<span className={activeKey === 'english' ? style.activeTab : ''}>English</span>}>
                        <div dangerouslySetInnerHTML={{ __html: data?.description }} />
                    </Tab>
                    <Tab eventKey="german" title={<span className={activeKey === 'german' ? style.activeTab : ''}>German</span>}>
                        <div dangerouslySetInnerHTML={{ __html: data?.g_description }} />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default PrivacyPolicyDetails