import React, { useEffect } from 'react'
import MotivationalMsgTable from '../components/motivationalmsgtable/MotivationalMsgTable'

const MotivationalMsg = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Motivational Messages')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <MotivationalMsgTable />
                </div>
            </div>
        </>
    )
}

export default MotivationalMsg