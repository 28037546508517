import { qs } from '../utils/utils';
import api from '../utils/api';

export const getCategory = async (data) => {
    try {
        const response = await api.get(`/get-category?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getOneCategory = async (data) => {
    try {
        const response = await api.get(`/one-category?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateCategory = async (data,params) => {
    try {
        const response = await api.post(`/update-category?${qs(params)}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const deleteOneCategory = async (data) => {
    try {
        const response = await api.delete(`/delete-category?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const addCategory = async (data) => {
    try {
        const response = await api.post(`/add-category`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const searchCategory = async (data) => {
    try {
        const response = await api.get(`/search-category?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}