import { qs } from '../utils/utils';
import api from '../utils/api';

export const getGuideline= async (data) => {
    try {
        const response = await api.get(`/get-guideline?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateGuideline= async (data,params) => {
    try {
        const response = await api.post(`/update-guideline?${qs(params)}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}