import { qs } from '../utils/utils';
import api from '../utils/api';

export const getAffirmation = async (data) => {
    try {
        const response = await api.get(`/get-affirmation?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getOneAffirmation = async (data) => {
    try {
        const response = await api.get(`/one-affirmation?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const deleteOneAffirmation = async (data) => {
    try {
        const response = await api.delete(`/delete-affirmation?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateAffirmation = async (data, params) => {
    try {
        const response = await api.post(`/update-affirmation?${qs(params)}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const addAffirmation = async (data) => {
    try {
        const response = await api.post(`/add-affirmation`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}


export const searchAffirmation = async (data) => {
    try {
        const response = await api.get(`/search-affirmation?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}