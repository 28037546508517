import { qs } from '../utils/utils';
import api from '../utils/api';

export const getSupport = async (data) => {
    try {
        const response = await api.get(`/get-support?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}   

export const searchSupport = async (data) => {
    try {
        const response = await api.get(`/search-support?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}