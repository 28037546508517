import React, { useEffect, useState } from 'react';
import style from './focusaffirmationcategoriestable.module.css';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Pagination from '../pagination/Pagination';
import { FiEdit } from 'react-icons/fi';
import 'react-h5-audio-player/lib/styles.css';
import deletemodalimage from '../../assets/delete.png'
import PaginationDropDown from '../paginationdropdown/PaginationDropDown';
import { deleteOneCategory, getCategory, searchCategory } from '../../services/CategoryService';
import Loading from '../loading/Loading';

const FocusAffirmationCategoriesTable = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [number, setNumber] = useState(10);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [deleteId, setDeleteId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const user = JSON.parse(localStorage.getItem('userdata'));

    const params = {
        created_by: user?.id,
        skip: currentPage * number,
        limit: number,
        name: searchQuery,
        isAdmin:true
    };

    useEffect(() => {
        getCategoryData();
    }, [currentPage, number, searchQuery]);

    const getCategoryData = async () => {
        try {
            setLoading(true)
            if (searchQuery) {
                const response = await searchCategory(params);
                setData(response?.data);
                setTotalItems(response?.total);
            } else {
                const response = await getCategory(params);
                setData(response?.data);
                setTotalItems(response?.total);
            }
            setLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleOnDelete = async () => {
        try {
            await deleteOneCategory({ id: deleteId });
            setShowModal(false);
            getCategoryData();
            if (data?.length === 1 || undefined) {
                setCurrentPage(0)
            }
        } catch (error) {
            console.error("Error deleting category:", error);
        }
    };

    const handleLimitChange = (newNumber) => {
        setNumber(Number(newNumber));
        setCurrentPage(0);
    };

    const reformatDate = (dateStr) => {
        const dateObj = new Date(dateStr);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateObj.getUTCMonth()];
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return `${month} ${day}, ${year}`;
    };

    return (
        <>
            {loading && <Loading />}
            <div className='mt-sm-4 mt-2'>
                <div className='mt-sm-4 d-lg-flex mb-sm-3 mb-2 justify-content-between'>
                    <div className='d-sm-flex gap-3 align-items-center'>
                        <div className='d-flex align-items-center gap-2'>
                            <p className={style.content}>Per Page :</p>
                            <PaginationDropDown number={number} setNumber={handleLimitChange} setCurrentPage={setCurrentPage} />
                            <button className={`btn d-sm-none ${style.button} ms-auto`} onClick={() => navigate('/focuses-affirmation-category/add-category')} >
                                <svg className='me-2' width="18" height="18" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17" cy="17" r="16.5" stroke="white" strokeDasharray="4 4" />
                                    <path d="M10.5833 16.9997H23.4166M17 10.583V23.4163" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                               Add New
                            </button>
                        </div>
                        <InputGroup className={`${style.searchbar} mt-sm-0 mt-1`}>
                            <InputGroup.Text id="inputGroup-sizing-default" className={style.searchButton}>
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.875 21.1875L17.625 15.9375C18.8438 14.3438 19.5938 12.375 19.5938 10.2188C19.5938 5.0625 15.375 0.84375 10.2188 0.84375C5.0625 0.84375 0.75 5.0625 0.75 10.2188C0.75 15.375 4.96875 19.5938 10.125 19.5938C12.2812 19.5938 14.3438 18.8438 15.9375 17.5312L21.1875 22.7813C21.375 22.9688 21.75 23.1562 22.0312 23.1562C22.3125 23.1562 22.5938 23.0625 22.875 22.7813C23.3438 22.4063 23.3438 21.6562 22.875 21.1875ZM3.09375 10.2188C3.09375 6.375 6.28125 3.1875 10.125 3.1875C13.9688 3.1875 17.1562 6.375 17.1562 10.2188C17.1562 14.0625 13.9688 17.25 10.125 17.25C6.28125 17.25 3.09375 14.1563 3.09375 10.2188Z" fill="black" />
                                </svg>
                            </InputGroup.Text>
                            <Form.Control
                                type='text'
                                className={style.inputfieldsearch}
                                placeholder={'Search by name'}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <button className={`btn d-sm-inline d-none ${style.button} me-2 mt-xl-0 mt-2`} onClick={() => navigate('/focuses-affirmation-category/add-category')} >
                        <svg className='me-2' width="18" height="18" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17" cy="17" r="16.5" stroke="white" strokeDasharray="4 4" />
                            <path d="M10.5833 16.9997H23.4166M17 10.583V23.4163" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                       Add New
                    </button>
                </div>
                <div id="table-container" className={`${style.tableContainer} mt-2`}>
                    <Table responsive borderless>
                        <thead>
                            <tr className={`${style.rowheading} ${style.section1}`}>
                                <th style={{ paddingLeft: '25px' }}>Name</th>
                                <th style={{ paddingLeft: '25px' }}>Name</th>
                                <th>Type</th>
                                <th>Created On</th>
                                <th>Status</th>
                                <th className='text-center'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className={style.tabledata}>
                            {
                                data?.length > 0 ?
                                    data?.map((record, index) => (
                                        <tr key={index}>
                                            <td style={{ paddingLeft: '25px' }}>
                                                {record?.name}
                                            </td>
                                            <td style={{ paddingLeft: '25px' }}>
                                                {record?.g_name}
                                            </td>
                                            <td>{record?.type === 0 ? 'Focus' : 'Affirmation'}</td>
                                            <td>{reformatDate(record?.createdAt)}</td>
                                            <td>{record?.status === 0 ? <div className={style.inactive}>In Active</div> : <div className={style.active}>Active</div>}</td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-3'>
                                                    <FiEdit style={{ cursor: 'pointer' }} className={style.edit} onClick={() => navigate(`/focuses-affirmation-category/edit-category/${record?._id}`)} />
                                                    <RiDeleteBinLine style={{ cursor: 'pointer' }} className={style.delete} onClick={() => { setDeleteId(record?._id); setShowModal(true) }} /></div>
                                            </td>
                                        </tr>
                                    )) :
                                    <tr className='text-center'>
                                        <td colSpan={6}><h4 className='p-4'>Data Not Found</h4></td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    {totalItems > number ?
                        <Pagination
                            pageCount={Math.ceil(totalItems / number)}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        />
                        : ''
                    }
                </div>
                <Modal size='sm' show={showModal} onHide={() => setShowModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-3'>
                    <Modal.Header className='border-0 p-0 justify-content-center'>
                        <img src={deletemodalimage} alt="Success" style={{ height: '100px' }} />
                    </Modal.Header>
                    <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center mt-3 mb-0`}>Are you sure you want to delete this focus / affirmation category?</h1></Modal.Body>
                    <Modal.Footer className='px-0 border-0 justify-content-center'>
                        <Button className={style.submitbutton} onClick={handleOnDelete}>
                            Delete
                        </Button>
                        <Button className={style.cacelbutton} onClick={() => { setShowModal(false) }}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>
    );
}

export default FocusAffirmationCategoriesTable;