import React, { useEffect } from 'react'
import AffirmationTable from '../components/affirmationtable/AffirmationTable'

const Affirmation = ({ setHeading }) => {

    useEffect(() => {
        setHeading('Affirmation')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <AffirmationTable />
                </div>
            </div>
        </>
    )
}

export default Affirmation