import React, { useEffect, useState } from 'react';
import style from './contactsupporttabletable.module.css';
import { Form, InputGroup, Table } from 'react-bootstrap';
import Pagination from '../pagination/Pagination';
import PaginationDropDown from '../paginationdropdown/PaginationDropDown';
import { FaSearch } from 'react-icons/fa';
import { getSupport, searchSupport } from '../../services/ContactSupportService';
import Loading from '../loading/Loading';

const ContactSupportTable = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [number, setNumber] = useState(10);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);

    const params = {
        skip: currentPage * number,
        limit: number,
        name: searchQuery,
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleLimitChange = (newNumber) => {
        setNumber(Number(newNumber));
        setCurrentPage(0);
    };

    useEffect(() => {
        getSupportData();
    }, [currentPage, number, searchQuery]);

    const getSupportData = async () => {
        try {
            setLoading(true);
            if (searchQuery) {
                const response = await searchSupport(params);
                setData(response?.data);
                setTotalItems(response?.total);
            } else {
                const response = await getSupport(params);
                setData(response?.data);
                setTotalItems(response?.total);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const reformatDate = (dateStr) => {
        const dateObj = new Date(dateStr);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateObj.getUTCMonth()];
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return `${month} ${day}, ${year}`;
    };

    return (
        <>
            {loading && <Loading />}
            <div className='mt-sm-4 mt-2'>
                <div className='d-sm-flex gap-3 align-items-center mt-sm-4 mb-sm-3 mb-2'>
                    <div className='d-flex align-items-center gap-2'>
                        <p className={style.content}>Per Page :</p>
                        <PaginationDropDown className='ms-auto' number={number} setNumber={handleLimitChange} setCurrentPage={setCurrentPage} />
                    </div>
                    <InputGroup className={`${style.searchbar} mt-sm-0 mt-1`}>
                        <InputGroup.Text id="inputGroup-sizing-default" className={style.searchButton}>
                            <FaSearch />
                        </InputGroup.Text>
                        <Form.Control
                            type='text'
                            className={style.inputfieldsearch}
                            placeholder={'Search by name'}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <div id="table-container" className={`${style.tableContainer} mt-2`}>
                    <Table responsive borderless>
                        <thead>
                            <tr className={`${style.rowheading} ${style.section1}`}>
                                <th style={{ paddingLeft: '25px' }}>User Name</th>
                                <th>Email Id</th>
                                <th>Comment</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody className={style.tabledata}>
                            {
                                data?.length > 0 ?
                                    data.map((record, index) => (
                                        <tr key={index}>
                                            <td style={{ paddingLeft: '25px' }}>
                                                {record?.name}
                                            </td>
                                            <td>{record?.email}</td>
                                            <td>{record?.comment}</td>
                                            <td>{reformatDate(record?.createdAt)}</td>
                                        </tr>
                                    )) :
                                    <tr className='text-center'>
                                        <td colSpan={6}><h4 className='p-4'>Data Not Found</h4></td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    {totalItems > number ?
                        <Pagination
                            pageCount={Math.ceil(totalItems / number)}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        />
                        : ''
                    }
                </div>
            </div >
        </>
    );
}

export default ContactSupportTable;