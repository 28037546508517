import React, { useEffect, useState } from 'react'
import Loading from '../loading/Loading';
import style from './imagetextbar.module.css'
import ImageTextForm from '../imagetextform/ImageTextForm';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { getScreen } from '../../services/ImageText';

const ImageTextBar = () => {
    const [data, setData] = useState([]);
    const [imageData, setImageData] = useState('');
    const [loading, setLoading] = useState(false);
    const [showView, setShowView] = useState(false);
    const [activeKey, setActiveKey] = useState('english');

    useEffect(() => {
        getScreenData();
    }, []);

    const getScreenData = async () => {
        try {
            setLoading(true);
            const response = await getScreen();
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleButtonClick = (item, index) => {
        setImageData(item)
        setShowView(true);
    };

    return (
        <>
            {loading && <Loading />}
            <Tabs
                id="fill-tab-example"
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                className="mb-3 border-0 mt-sm-4 mt-2 "
            >
                <Tab eventKey="english" cl title={<span className={activeKey === 'english' ? style.activeTab : ''}>English</span>}>
                    <div className='row'>
                        {data?.map((item, index) => (
                            <div key={index} className={`col-xl-3 col-sm-6 mb-4`}>
                                <div className={style.card}>
                                    <div className={style.imageContainer}>
                                        <img src={item?.image} className={style.image} alt="" />
                                        <div className={style.overlay}>
                                            <p className={style.quote}>{item?.quote}</p>
                                            <p className={`${style.author} mt-2`} >{item?.authorName}</p>
                                        </div>
                                    </div>
                                    <div className={style.buttonContainer}>
                                        <button className={style.button} onClick={() => handleButtonClick(item, index)}>
                                            Change image & text
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="german" title={<span className={activeKey === 'german' ? style.activeTab : ''}>German</span>}>
                <div className='mt-sm-4 mt-2 row'>
                        {data?.map((item, index) => (
                            <div key={index} className={`col-xl-3 col-sm-6 mb-4`}>
                                <div className={style.card}>
                                    <div className={style.imageContainer}>
                                        <img src={item?.image} className={style.image} alt="" />
                                        <div className={style.overlay}>
                                            <p className={style.g_quote}>{item?.g_quote}</p>
                                            <p className={`${style.author} mt-2`} >{item?.authorName}</p>
                                        </div>
                                    </div>
                                    <div className={style.buttonContainer}>
                                        <button className={style.button} onClick={() => handleButtonClick(item, index)}>
                                            Change image & text
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Tab>
            </Tabs>
            <Modal size='xl' show={showView} onHide={() => setShowView(false)} centered backdrop='static' keyboard={false} >
                <Modal.Header closeButton className='border-0 mb-2'>
                    <Modal.Title>Change image & text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageTextForm setShowView={setShowView} initialData={imageData} getScreenData={getScreenData} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ImageTextBar