import { qs } from '../utils/utils';
import api from '../utils/api';

export const getMessage = async (data) => {
    try {
        const response = await api.get(`/get-message?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getOneMessage = async (data) => {
    try {
        const response = await api.get(`/one-message?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateMessage = async (data,params) => {
    try {
        const response = await api.post(`/update-message?${qs(params)}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const deleteOneMessage = async (data) => {
    try {
        const response = await api.delete(`/delete-message?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const addMessage = async (data) => {
    try {
        const response = await api.post(`/add-message`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const searchMessage = async (data) => {
    try {
        const response = await api.get(`/search-message?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}